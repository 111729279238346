import React from 'react'

import Image from './Image'
import {HTMLContent} from './Content'

class About extends React.Component {
    constructor(data) {
        super(data)
        this.aboutImage = data.aboutImage
        this.body = data.body
    }

    render() {
        return (
            <section id="about" className="section alt-section about">
                <h1>About Blaze</h1>
                <div className="container about__content">
                    <Image image={this.aboutImage} className="about__image"/>
                    <div>
                        <HTMLContent content={this.body}/>
                        <div className="about__button secondary-button">
                            <a href="/#contact">
                                <div className="arrow-button">
                                    <span className="arrow-button__inner">Make a booking</span>
                                    <svg viewBox="0 0 481.5 293.5"><defs></defs><path fill="currentColor" d="M468.6 59.6l-46.7-46.7-187.2 187.2L46.7 12 0 58.7l188.1 188 46.6 46.7.1.1 46.7-46.7-.1-.1z"></path></svg>
                                </div>  
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default About