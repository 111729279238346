import React, { useState, useEffect } from 'react';
import { Parallax } from 'react-scroll-parallax'

import Image from './Image'

/**
 * This function handles the order and style of the main section.
 * This is because on desktop view is different to the mobile
 */
export default ({
    image,
    heading,
    subheading,
    cta
}) => {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
    
        window.addEventListener("resize", handleResize);

        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, [])

    return (
        <main className={windowSize.width >= 1024 ? 'grid' : null}>
            {windowSize.width >= 1024 && <>
                <div className="column">
                    <Parallax y={[-20, 60]} tagOuter="figure">
                        <span className="heading">{heading}</span>
                        <p className="subheading">{subheading}</p>
                        <a href={cta.href}>
                            <button className="primary-button">
                                {cta.text}
                            </button>
                        </a>
                    </Parallax>
                </div>
                <div className="column">
                    <Image id="heroImage" className="hero__image" image={image}/>
                </div>
            </>}
            {windowSize.width < 1024 && <>
                <span className="heading">{heading}</span>
                <Image id="heroImage" className="hero__image" image={image}/>
                <p className="subheading">{subheading}</p>
                <a href={cta.href}>
                    <button className="primary-button">
                        {cta.text}
                    </button>
                </a>
            </>}  
        </main>
    )
}