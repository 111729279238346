import React from 'react';
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { ParallaxProvider } from 'react-scroll-parallax'

import '../assets/index.scss'

import Hero from '../components/Hero'
import About from '../components/About'
import Layout from '../components/Layout'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Videos from '../components/Videos'
import Contact from '../components/Contact'
import Testimonials from '../components/Testimonials'

export const SinglePageTemplate = ({
    image,
    heading,
    subheading,
    cta,
    videos,
    about,
    aboutImage,
    testimonials,
    phoneNumber,
    email,
    contactReceivedMessage,
    formUrl,
    menuItems
}) => {
    const heroProvided = image && image.path !== 'empty.svg'
        && heading && subheading && cta && cta.href && cta.text

    return (
        <div className="page">
            <div id="page__header" className="page__header page__header--home">
                <Navbar pageMenuItems={menuItems} />
                {heroProvided &&
                    <Hero cta={cta} image={image} heading={heading} subheading={subheading} />
                }
            </div>
            {videos && videos.length > 0 &&
                <Videos videos={videos} />
            }
            {about && aboutImage &&
                <About aboutImage={aboutImage} body={about} />
            }
            {testimonials && testimonials.length > 0 &&
                <Testimonials testimonials={testimonials} />
            }
            <Contact
                phoneNumber={phoneNumber}
                email={email}
                contactReceivedMessage={contactReceivedMessage}
                formUrl={formUrl} />
            <Footer pageMenuItems={menuItems} />
        </div>
    )
}


SinglePageTemplate.propTypes = {

}


const SinglePage = ({ data }) => {
    const { frontmatter, html } = data.markdownRemark

    return (
        <ParallaxProvider>
            <Layout pageTitle="Home">
                <SinglePageTemplate
                    image={frontmatter.image}
                    heading={frontmatter.heading}
                    subheading={frontmatter.subheading}
                    cta={frontmatter.cta}
                    videos={frontmatter.videos}
                    about={html}
                    aboutImage={frontmatter.aboutImage}
                    testimonials={frontmatter.testimonials}
                    email={frontmatter.email}
                    phoneNumber={frontmatter.phoneNumber}
                    formUrl={frontmatter.formUrl}
                    contactReceivedMessage={frontmatter.contactReceivedMessage}
                    menuItems={frontmatter.menuItems}
                />
            </Layout>
        </ParallaxProvider>
    )
}

SinglePage.propTypes = {
    data: PropTypes.shape({
        markdownRemark: PropTypes.shape({
            frontmatter: PropTypes.object,
            html: PropTypes.string
        })
    })
}

export default SinglePage

export const pageQuery = graphql`
    query PageQueryTemplate {
        markdownRemark(frontmatter: { templateKey: {eq: "single-page"} }) {
            frontmatter {
                menuItems {
                    title
                    link
                    children {
                      title
                      link
                    }
                }
                image {
                    childImageSharp {
                        fluid(maxWidth: 1000) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                aboutImage {
                    childImageSharp {
                        fluid(maxWidth: 1000) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                heading
                subheading
                menuItems {
                    title
                    link
                }
                cta {
                    text
                    href
                }
                videos {
                    title
                    videoURL
                }
                testimonials {
					quote
					author
					company
					authorLink
                    hideRating
                    logo {
                        svg {
                            content
                            originalContent
                        }
                        internal {
                            mediaType
                        }
                    }
				}
                phoneNumber
	            email
	            contactReceivedMessage
	            formUrl
            }
            html
        }
    }
`