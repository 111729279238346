
import React from 'react'

import { FiveStars } from './Rate'
import Image from './Image'

const Testimonial = ({ testimonial }) => {
    const { author, quote, company, authorLink, logo, hideRating } = testimonial
    return (
        <section className="quotes-carousel">
            <div className="quotes-carousel__quote quotes-carousel__quote--active">
                <div className="quote">
                    <blockquote className="quote__inner">
                        <div className="quote__logo">
                            <TestimonialLogo logo={logo}/>
                        </div>
                        <footer className="quote__author">
                            <a target="_blank" href={authorLink || "#"} rel="noopener noreferrer" className="quote__info">
                                {author && author.split('\n').map((line, i, a) => {
                                    return (
                                        <span className="quote__author--text" key={i}>
                                            {line}{a.length === i+1 ? '' : ','}
                                            <br/>
                                        </span>
                                    ) 
                                })}
                                {company}
                            </a>
                        </footer>
                        <div className="quote__text">
                            <p>“{quote}”</p>
                        </div>
                        {!hideRating && <div className="quote__footer">
                            <FiveStars/>
                        </div>}
                    </blockquote>
                </div>
            </div>
        </section>
    )
}

export default Testimonial


export const TestimonialLogo = ({ logo }) => {
    if (!logo) return <div></div>;
    const normal = <div 
        className="quote__logo--image">
            <Image style={{width: '100%', height: '100%'}} image={logo}/>
        </div>
    // If SVG
    if (logo.internal && logo.internal.mediaType === "image/svg+xml") {
        // Inline SVG
        if (logo.svg && logo.svg.content) {
            return <div 
                className="quote__logo--image" 
                dangerouslySetInnerHTML={{__html: logo.svg.originalContent}}/>
        }
        // Non-inline SVG
        return normal
    } else {
        return normal
    }
    // Non-SVG
}