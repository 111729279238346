import React, { useState } from 'react'
import InView from 'react-intersection-observer'

import { NumbersCarousel } from './Carousel'
import Testimonial from './Testimonial'

const Testimonials = ({testimonials}) => {
    testimonials = testimonials ?? []

    const slideDuration = 10 * 1000

    const [ticker, setTicker] = useState(0)
    const incrementTicker = () => setTicker(ticker + 1)
    
    const [autoPlay, setAutoPlay] = useState(false)
    const toggleAutoPlay = inView => {
        setAutoPlay(inView)
        incrementTicker()
    }

    return (
        <InView as="div" onChange={toggleAutoPlay}>
            <div id="testimonials" className="section">
                <h1>Testimonials</h1>
                <NumbersCarousel
                    enableAutoPlay={autoPlay}
                    autoPlaySpeed={slideDuration}
                    // Reset Ticker
                    onChange={incrementTicker}> 
                    {
                        testimonials.map((testimonial, i) => {
                            return (
                                <Testimonial key={i} testimonial={testimonial}/>
                            )
                        })
                    }
                </NumbersCarousel>
                <div key={ticker} className="carousel__ticker"/>
            </div>
        </InView>
    )
}

export default Testimonials